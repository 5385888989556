<template>
  <div role="main" class="main">
    <header class="header">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <nav class="navbar navbar-expand-md navbar-dark pl-lg-0">
              <a class="navbar-brand" href="javascript:void(0);">{{
                event ? event.wed_name : "WED WITH US"
              }}</a>
              <div class="navbar-list">
                <router-link
                  tag="a"
                  class="text-yellow hover-yellow mr-3 mr-md-5"
                  to="/speaker/profile"
                  >Profile</router-link
                >
              </div>
              <div class="action logout">
                <a
                  href="#"
                  class="text-yellow hover-yellow mr-5"
                  @click="logout"
                  ><i class="fas fa-sign-out-alt"></i>Logout</a
                >
                <button class="btn btn-danger" @click="goLive">
                  Start Service
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
    <section class="together_we_join minh-custom with-footer speaker-room-page">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p class="text-uppercase">
              Together we join you. We are here with you
            </p>
            <ul class="list-unstyled">
              <li
                v-for="visitor in visitors"
                :key="visitor.visitor_id"
                class="mr-3"
              >
                <a href="#">
                  <img
                    :src="visitor.profile_url"
                    class="img-fluid br-50 fixed-image"
                  />
                </a>
                <br />
                <small>{{ visitor.full_name }}</small>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <footer class="container">
      <div class="row align-items-center">
        <div class="col-md-7">
          <div class="participate speaker_list">
            <ul class="list-group list-group-horizontal-md">
              <li
                v-for="speaker in speakers"
                :key="speaker.speaker_id"
                class="list-group-item py-1 px-0"
                style="background-color: black; margin-right: 0px"
              >
                <!-- <div class="videosession"> </div>-->
                <div
                  id="publisher"
                  v-if="
                    speaker.content_type_id == content_type_id &&
                    speaker.speaker_type == 1
                  "
                  class="img-fluid call"
                  style="display: none"
                ></div>
                <div
                  id="subscriber"
                  v-if="
                    speaker.content_type_id != content_type_id &&
                    speaker.speaker_type == 1
                  "
                  class="call-subscriber"
                ></div>

                <div style="margin-right: 15px">
                  <img
                    v-if="
                      speaker.content_type_id != content_type_id &&
                      speaker.speaker_type == 0
                    "
                    :src="speaker.profile_url"
                    class="img-fluid"
                  />
                </div>
                <div
                  class="text-label"
                  v-if="
                    speaker.content_type_id != content_type_id &&
                    speaker.speaker_type == 0
                  "
                >
                  <span>VIDEO</span>
                </div>
                <div style="margin-right: 15px">
                  <img
                    v-if="
                      speaker.content_type_id == content_type_id &&
                      speaker.speaker_type == 0
                    "
                    :src="speaker.profile_url"
                    class="img-fluid"
                  />
                </div>
                <div
                  class="text-label"
                  v-if="
                    speaker.content_type_id == content_type_id &&
                    speaker.speaker_type == 0
                  "
                >
                  <span>VIDEO</span>
                </div>

                <div class="video-tag" v-show="speaker.video_status == true">
                  <video
                    :id="'video_' + speaker.content_type_id"
                    class="profile-video"
                    autoplay
                    playsinline
                    muted
                    v-show="speaker.video_status == true"
                  ></video>
                </div>
                <button
                  :id="'button_' + speaker.content_type_id"
                  class="btn-play"
                  v-show="speaker.video_status == true"
                  disabled
                >
                  Unmute
                </button>
                <!-- <button :id="'button_switch_' + speaker.content_type_id" class=btn-play v-show="speaker.video_status == true" disabled>Switch Camera</button> -->
                <div :id="'divCamera_' + speaker.content_type_id" hidden>
                  <!-- <select name="cameras" :id="'selectCamera_' + speaker.content_type_id" v-show="speaker.video_status == true" hidden>
                    </select> -->
                  <b-nav-item-dropdown id="dropdown-list" no-caret right>
                    <template slot="button-content">
                      <i
                        class="fas fa-camera"
                        style="font-size: 36px; color: #fcd351"
                        @click="deviceList()"
                      ></i>
                    </template>
                    <b-dropdown-item
                      :id="'deviceid_' + device.deviceId"
                      v-for="device in devices"
                      :key="device.deviceId"
                      @click="switchCamera($event)"
                      >{{ device.label }}</b-dropdown-item
                    >
                    <b-dropdown-divider></b-dropdown-divider>
                  </b-nav-item-dropdown>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-5 text-center text-lg-right" v-if="event">
          <img
            :src="event.program_url"
            class="img-fluid pointer"
            style="max-height: 110px"
            @click="enlarge()"
          />
        </div>
        <!--        <div class="col-md-3 text-center text-lg-right">-->
        <!--          <p class="text-white">40 People<br> are participating in this event</p>-->
        <!--        </div>-->
        <!--        <div class="col-md-2 text-center text-lg-right">-->
        <!--          <img :src="require('@/assets/img/obituary-sm.png')" class="img-fluid">-->
        <!--        </div>-->
      </div>
    </footer>

    <!-- Modal -->
    <transition name="pop" appear>
      <div
        class="modal fade show program-modal"
        v-if="show_modal"
        id="myModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModal"
        aria-hidden="true"
        @click="closeModal"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="row" v-if="event">
                <div class="col-lg-12">
                  <img :src="event.program_url" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import {
  apiBaseUrl,
  admin_key,
  company_wait_prefix,
  liveBaseUrl,
} from "../../constants/config";
import { mapActions } from "vuex";
import { janusUrl, iceServer } from "../../constants/config";
import Room from "janus-room";

import adapter from "webrtc-adapter";
window["adapter"] = adapter;

export default {
  data() {
    return {
      speakers: [],
      visitors: [],
      checkTimer: null,
      event: null,
      event_id: null,
      content_type_id: null,
      show_modal: false,
      connection: null,
      devices: [],
    };
  },
  mounted() {
    getApiManager()
      .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
        live: 1,
        content_type_id: this.content_type_id,
      })
      .then((response) => {});
    this.getDetail();
    // this.checkTimer = setInterval(() => {
    //   this.checkLive()
    // }, 1000 * 10)
    this.connectSocket();
  },
  methods: {
    ...mapActions(["signOut"]),
    connectSocket() {
      var ref = this;

      this.connection = new WebSocket(liveBaseUrl);
      this.connection.onmessage = function (event) {
        //this.isLoading = false;
        let data = JSON.parse(event.data);
        console.log("&&&&&&&&&&&&&&&&&", data);
        if (data.type == "change_order") {
          console.log("44444444444444!!");
          ref.receiveMoveLive(data.order);
        } else if (data.type == "user_info") {
          console.log("!!!!!!!!!!!!!!!!!!!!");
          ref.connection.send(
            JSON.stringify({
              type: "my_info",
              event_id: ref.event_id,
            })
          );
        } else if (data.type == "event_distroy") {
          // localStorage.setItem("Live_id", null);
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          ref.$router.push("/speaker/profile?x=" + randomnumber);
          // window.location.reload();
        }
      };

      this.connection.onclose = (e) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second."
        );
        setTimeout(() => {
          ref.connectSocket();
        }, 1000);
      };
    },
    logout() {
      if (self.OTSession) {
        console.log("&&&&&&&&&&&&&", self.OTSession);
        self.OTSession.disconnect();
      }
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
          live: 0,
          content_type_id: this.content_type_id,
        })
        .then((response) => {
          localStorage.setItem("Live_id", null);
          localStorage.setItem("deceased_name",'')
          this.signOut();
          this.$router.push("/login");
          window.location.reload();
        });
    },
    receiveMoveLive(order) {
      this.connection.close();
      if (order == 1) {
        this.stopLive();
        let randomnumber = Math.floor(Math.random() * 10000) + 1;
        this.$router.push("/speaker/start/"+this.event_id+"?x=" + randomnumber);
      } else if (order == 2) {
        this.stopLive();
        let randomnumber = Math.floor(Math.random() * 10000) + 1;
        this.$router.push("/speaker/live/"+this.event_id+"?x=" + randomnumber);
      }
    },
    closeModal() {
      this.show_modal = false;
    },
    enlarge() {
      this.show_modal = true;
    },
    changeSpeakerVideoStatus(index, status) {
      for (var i = 0; i < this.speakers.length; i++) {
        if (this.speakers[i].content_type_id == index) {
          this.speakers[i].video_status = status;
        }
      }
      this.$forceUpdate();
    },
    deviceList() {
      var videoDevices = this.room.listVideoDevices();
      var devices = [];
      videoDevices.forEach(function (device) {
        var label = device.label;
        if (!label || label === "") label = device.deviceId;

        if (device.kind === "videoinput") {
          //console.log("videoinput***"+option);
          // var option = document.createElement("option");
          // option.text = label;
          // option.value = device.deviceId;
          // selectElement.appendChild(option);
          devices.push(device);
        }
      });

      this.devices = devices;
    },
    onLocalJoin() {
      console.log("inside onlocalJoin");

      if (
        document.getElementById("selectCamera_" + this.content_type_id) !== null
      ) {
        console.log("inside selectCamera_");
        document
          .getElementById("selectCamera_" + this.content_type_id)
          .remove();
      }

      let target = document.getElementById("video_" + this.content_type_id);
      //target.muted = true;
      this.room.attachStream(target, 0);
      //target.volume = 0;
      this.changeSpeakerVideoStatus(this.content_type_id, true);
      let buttonTarget = document.getElementById(
        "button_" + this.content_type_id
      );
      buttonTarget.hidden = true;
      var videoDevices = this.room.listVideoDevices();
      console.log("we got the devices****" + this.room.listVideoDevices());
      let selectCameraTarget = document.getElementById(
        "divCamera_" + this.content_type_id
      );
      selectCameraTarget.hidden = false;
    },
    onRemoteJoin(index, remoteUsername, feedId) {
      let splits = remoteUsername.split("speaker_");
      if (splits.length == 2) {
        let content_type_id = splits[1];
        let target = document.getElementById("video_" + content_type_id);
        this.room.attachStream(target, index);
        this.changeSpeakerVideoStatus(content_type_id, true);
        //target.muted = false;
        let buttonTarget = document.getElementById("button_" + content_type_id);
        buttonTarget.disabled = false;
        buttonTarget.style.backgroundColor = "#fcd351";
        buttonTarget.addEventListener("click", () => {
          target.muted = false;
          buttonTarget.hidden = true;
        });
      }
    },
    onRemoteUnjoin(index, remoteUsername) {
      let splits = remoteUsername.split("speaker_");
      if (splits.length == 2) {
        let content_type_id = splits[1];
        this.changeSpeakerVideoStatus(content_type_id, false);
      }
    },
    onMessage(data) {
      console.log(data);
    },
    onError(err) {
      console.log(err);
    },
    registerUserName() {
      this.room.register({
        username: "speaker_" + this.content_type_id,
        room: parseInt(company_wait_prefix) + this.event_id,
      });
    },
    createRoom() {
      var ref = this;
      this.room
        .createRoom({
          room: parseInt(company_wait_prefix) + this.event_id,
          publishers: 100,
          admin_key: admin_key,
          videocodec: "h264",
          h264_profile: "42e01f",
        })
        .then(() => {
          setTimeout(function () {
            ref.registerUserName();
          }, 1000);
        })
        .catch((err) => {
          alert(err);
        });
    },
    stopLive() {
      this.room.unpublishOwnFeed();
    },
    switchCamera(event) {
      var cameraId = event.target.id.substring(
        event.target.id.indexOf("_") + 1
      );
      console.log("You selected: " + cameraId);
      var options = {
        username: "speaker_" + this.content_type_id,
        room: parseInt(company_wait_prefix) + this.event_id,
      };
      this.room.switchCamera(cameraId, options);
      //document.getElementById('selectCamera_' + this.content_type_id).remove();
    },
    startLive() {
      var publishOwnFeed = true;
      var videocodec = "h264";
      var h264_profile = "42e01f";
      var options = {
        server: janusUrl, // required
        iceservers: iceServer,
        // Event handlers
        onLocalJoin: this.onLocalJoin,
        onRemoteJoin: this.onRemoteJoin,
        onRemoteUnjoin: this.onRemoteUnjoin,
        onMessage: this.onMessage,
        onError: this.onError,
        room: parseInt(company_wait_prefix) + this.event_id,
        publishOwnFeed: publishOwnFeed,
        videocodec: videocodec,
        h264_profile: h264_profile,
      };
      this.room = new Room(options);
      var ref = this;
      this.room
        .init()
        .then(function () {
          ref.createRoom();
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    checkLive() {
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/event/get`)
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            let detail = res.detail;
            if (detail.status == 1) {
              clearInterval(this.checkTimer);
              this.stopLive();
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/speaker/start/"+this.event_id+"?x=" + randomnumber);
            } else if (detail.status == 2) {
              clearInterval(this.checkTimer);
              this.stopLive();
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/speaker/live/"+this.event_id+"?x=" + randomnumber);
            }
          }
        })
        .catch((error) => {});
    },
    getDetail() {
      this.event_id = JSON.parse(localStorage.getItem("loginInfo"))
        ? JSON.parse(localStorage.getItem("loginInfo")).event_id
        : 0;
      this.content_type_id = JSON.parse(localStorage.getItem("loginInfo"))
        ? JSON.parse(localStorage.getItem("loginInfo")).id
        : 0;

      var ref = this;
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/all`)
        .then((response) => {
          let res = response.data;

          if (res.status === true) {
            let detail = res.detail;
            ref.speakers = detail.speakers;
            var i;
            for (i = 0; i < ref.speakers.length; i++) {
              console.log("___________________________", ref.speakers[i]);
              ref.speakers[i].profile_url =
                apiBaseUrl + "/" + ref.speakers[i].profile_url;
              ref.speakers[i].video_status = false;
              if (
                this.content_type_id == ref.speakers[i].content_type_id &&
                ref.speakers[i].speaker_type == 1
              ) {
                this.unpublish = true;
              }
            }
            ref.visitors = detail.visitors;
            for (i = 0; i < ref.visitors.length; i++) {
              ref.visitors[i].profile_url =
                apiBaseUrl + "/" + ref.visitors[i].profile_url;
            }
            ref.event = detail.event;
            ref.event.program_url = apiBaseUrl + "/" + ref.event.program_url;
            ref.startLive();
          }
          if (this.unpublish) {
            this.publishCall();
          }
          // getApiManager()
          //   .post(`${apiBaseUrl}/api/vonage/generateToken`, {
          //     wed_name: this.event.wed_name,
          //   })
          //   .then((result) => {
          //     // console.log("ttttttttttt", result);
          //     this.publishCall()
          //   });
        })
        .catch((error) => {});
    },
    // publishCall() {
    //   getApiManager()
    //     .post(`${apiBaseUrl}/api/vonage/getToken`, {
    //       wed_name: this.event.wed_name,
    //     })
    //     .then((result) => {
    //       this.sessionId = result.data.data[0].sessionId;
    //       this.apikey = result.data.apiKey;
    //       this.token = result.data.data[0].token;
    //       self.OTSession = self.OT.initSession(
    //         result.data.apiKey,
    //         result.data.data[0].sessionId
    //       );

    //       var publisherOptions = {
    //         insertMode: "before",
    //         width: "100%",
    //         height: "100%",
    //       };
    //       self.OTpublisher = self.OT.initPublisher(
    //         "publisher",
    //         publisherOptions,
    //         handleError
    //       );

    //       // Connect to the session
    //       self.OTSession.connect(
    //         result.data.data[0].token,
    //         function callback(error) {
    //           if (error) {
    //             handleError(error);
    //           } else {
    //             // If the connection is successful, publish the publisher to the session
    //             self.OTSession.publish(self.OTpublisher, handleError);
    //           }
    //         }
    //       );
    //       function handleError(error) {
    //         if (error) {
    //           console.error(error);
    //         }
    //       }
    //     });
    //   // this.joinCall();
    // },
    publishCall() {
      getApiManager()
        .post(`${apiBaseUrl}/api/vonage/getToken`, {
          wed_name: this.event.wed_name,
        })
        .then((result) => {
          this.sessionId = result.data.data[0].sessionId;
          this.apikey = result.data.apiKey;
          this.token = result.data.data[0].token;

          // if (self.OTSession) {
          //   self.OTSession.disconnect();
          // }
          self.OTSession = self.OT.initSession(
            result.data.apiKey,
            result.data.data[0].sessionId
          );
          self.OTSubscriberArray = [];
          self.OTEVENTStream = "";
          self.OTSession.on("streamCreated", function streamCreated(event) {
            self.OTSubscriberArray = [];
            self.OTSubscriberArray = self.OTSession.getSubscribersForStream(
              event.stream
            );
            if (self.OTSubscriberArray.length > 0) {
              for (let i = 0; i < self.OTSubscriberArray.length; i++) {
                self.OTSession.unsubscribe(self.OTSubscriberArray[i]);
              }
            }
            var subscriberOptions = {
              insertMode: "before",
              width: "100%",
              height: "100%",
            };
            console.log("streamCreated");
            self.OTSubscriber = self.OTSession.subscribe(
              event.stream,
              "subscriber",
              subscriberOptions,
              handleError
            );

            // self.OTSubscriberArray = self.OTSession.getPublisherForStream(
            //   event.stream
            // );
            self.OTSubscriberArray = self.OTSession.getSubscribersForStream(
              event.stream
            );
            console.log("&&&&&&&&&&&&&&&", self.OTSubscriberArray);
            self.OTEVENTStream = event.stream;
          });

          self.OTSession.on("streamDestroyed", function streamCreated(event) {
            console.log("CALLL", event);
            // self.OTSession.disconnect();
          });

          var publisherOptions = {
            insertMode: "before",
            resolution: "1280x720",
            // width: "100%",
            // height: "100%",
          };
          self.OTpublisher = self.OT.initPublisher(
            "publisher",
            publisherOptions,
            handleError
          );

          // Connect to the session
          self.OTSession.connect(
            result.data.data[0].token,
            function callback(error) {
              if (error) {
                handleError(error);
              } else {
                // If the connection is successful, publish the publisher to the session
                self.OTSession.publish(self.OTpublisher, handleError);
              }
            }
          );
          function handleError(error) {
            if (error) {
              console.error(error.message);
              // this.$notify({
              //   group: "foo",
              //   type: "warn",
              //   title: "Error",
              //   text: "Server Error",
              //   animation_type: "slide",
              // });
            }
          }
        });
      // this.joinCall();
    },
    goLive() {
      localStorage.setItem("Live_id", this.content_type_id);
      var ref = this;

      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/event/modify`, { status: 1 })
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            clearInterval(this.checkTimer);
            //this.stopLive();
            ref.connection.send(
              JSON.stringify({
                type: "change_order",
                order: 1,
              })
            );
            let randomnumber = Math.floor(Math.random() * 10000) + 1;
            this.$router.push("/speaker/start/"+this.event_id+"?x=" + randomnumber);
          }
        })
        .catch((error) => {
          this.$notify({
            group: "foo",
            type: "warn",
            title: "Error",
            text: "Server Error",
            animation_type: "slide",
          });
        });
    },
    handlerClose(e, ev, evv) {
      console.log(e);
      console.log(ev);
      console.log(evv);

      this.removefromlive(function () {});
      // e.returnValue = "Are you sure want to leave?";
      console.log("Ddddddddddd");
    },
  },
  created() {
    window.onbeforeunload = this.handlerClose;
    // window.onunload = this.handlerClose;
    // window.addEventListener("focus", this.handlerunload);
    // console.log(window);
  },
  // beforeDestroy() {
  //   if (self.OTSubscriberArray) {
  //     console.log("((((((((((((((((((((((((((")
  //     for (let i = 0; i < self.OTSubscriberArray.length; i++) {
  //       self.OTSession.unsubscribe(self.OTSubscriberArray[i]);
  //     }
  //   }
  // },
  beforeDestroy() {
    //  getApiManager().post(`${apiBaseUrl}/api/speaker/event/modify`, { status: 0 })
    //             .then((response) => {
    //     });
  },
  destroyed() {
    if (self.OTSession) {
      self.OTSession.disconnect();
    }
  },
  components: {},
  computed: {},
  watch: {},
};
</script>
<style scoped>
.participate .list-unstyled {
  display: flex !important;
}
#subscriber {
  display: flex !important;
}
.participate .text-label {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -8px !important;
  margin-right: 15px;
  top: 0 !important;
  background-color: #00000000 !important;
  position: absolute !important;
}
.participate .text-label span {
  background-color: #000 !important;
  left: unset !important;
  top: unset !important;
}
</style>
